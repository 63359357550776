.under-construction {
  background: rgb(255, 255, 255);
  padding: 0 30px;
  height: 100vh;
  @media only screen and (max-width: 600px) {
    padding: 0;
  }

  .btn-container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px 0;
    display: flex;
    justify-content: flex-end;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    height: 80%;
    @media only screen and (max-width: 600px) {
      flex-direction: column-reverse;
      justify-content: center;
      padding: 0 20px;
    }

    .text {
      color: black;
      width: 60%;
      padding-right: 20px;

      @media only screen and (max-width: 600px) {
        width: 100%;
      }
      h1 {
        font-size: 48px;
        margin-bottom: 25px;
      }

      h3 {
        margin-bottom: -10px;
        font-weight: 100;
      }

      h2 {
        font-size: 24px;
        font-weight: 100;
        margin-bottom: 10px;
        padding-left: 15px;
        border-left: 3px solid #e4b96c;
      }

      a {
        text-decoration: none;
        color: #e4b96c;
        font-weight: bold;
        transform: translateX(0px);
        transition: transform 1.2s ease;
        &:hover .arrow {
          transform: translateX(5px);
        }

        .website-arrow,
        .website-text {
          display: inline-block;
        }

        .website-text {
          margin-left: 8px;
          color: #e4b96c;
        }
      }

      .cv {
        padding: 30px 0 0;
        display: flex;
        align-items: center;
        .social-icon {
          height: 35px;
          width: 35px;
          margin-right: 5px;
          color: #e4b96c;
          fill: #e4b96c;
          cursor: pointer;
          transition: transform 0.2s ease;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }

    .photo {
      width: 40%;
      display: flex;
      justify-content: center;
      @media only screen and (max-width: 600px) {
        width: 80%;
        margin: 0 auto;
        padding-bottom: 20px;
      }

      img {
        width: 100%;
      }
    }
  }
}

.btn {
  border: 2px solid #e4b96c;
  border-radius: 3px;
  padding: 10px 20px;
  font-weight: 700;
  color: #e4b96c;
  background: none;
  outline: none;
  cursor: pointer;
  margin: 0 10px 0 0px;
  text-align: center;

  transition: box-shadow 0.3s ease;
  &:hover {
    box-shadow: -2px 2px 0px #e4b96c;
  }

  &:active {
    box-shadow: none;
  }

  &--active {
    font-weight: 900;
  }
}
