h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}
